import React, { useRef } from "react";
import "./RecentBlogs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

function RecentBlogs() {
  const blogpost = [
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p1.png",
      title: "Black Pepper Essential Oils",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p2.png",
      title: "Bergamot Essential Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p3.png",
      title: "Bitter Orange Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p4.png",
      title: "Carrot Seed Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p1.png",
      title: "Black Pepper Essential Oils",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p2.png",
      title: "Bergamot Essential Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p3.png",
      title: "Bitter Orange Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assets/ProductCards/p4.png",
      title: "Carrot Seed Oil",
      price: "00.00",
    },
  ];

  const recentFeedsRef = useRef(null); // Create a ref for the recent feeds container

  const handleScroll = () => {
    if (recentFeedsRef.current) {
      recentFeedsRef.current.scrollTop = recentFeedsRef.current.scrollHeight;
    }
  };

  return (
    <>
      <section className="RecentBlogs">
        <div className="Recent-blogs">
          <div className="recent-border"></div>
          <h1 className="mid-title">Recent Feeds</h1>
        </div>
        <div className="row">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-12">
            <div className="blogs-Main">
              <div className="scrollbar" id="style-3">
                <div className="Recents" data-mcs-theme="dark">
                  <div className="recent-feeds" ref={recentFeedsRef}>
                    {blogpost.map((item, index) => (
                      <div key={index} className="blogs">
                        <div className="blog-feed">
                          <div className="content-blog">
                            <img src={item.image} className="feed" alt="Feed" />
                          </div>
                          <div className="feed-content">
                            <p className="text-feed">{item.title}</p>
                            <p className="text">{item.price}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="overlay"></div>
              <div className="icon" onClick={handleScroll}>
                <FontAwesomeIcon icon={faAngleDown} />
              </div>
            </div>
          </div>
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-6 col-12">
            <div className="categories mt-3">
              <h1 className="mid-title">Categories</h1>
              <p className="price-text hover-one">Phytochemical (8)</p>
              <p className="price-text hover-one">Carrier Oils (3)</p>
              <p className="price-text hover-one">Essential Oils (3)</p>

              <h1 className="mid-title">Popular Tags</h1>
              <div className="oils">
                <p className="price-text hover-two">Cosmetics</p>
                <p className="price-text hover-two">Health</p>
                <p className="price-text hover-two">Lifestyle</p>
              </div>
              <div className="oils">
                <p className="price-text hover-two">Natural</p>
                <p className="price-text hover-two">Rosemary Oil</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecentBlogs;
