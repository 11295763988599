import React from "react";
import "./WhoWeAre.css";
import {Col, Container, Row} from "react-bootstrap";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const WhoWeAre = () => {
    return (
        <>
            {/* WhoWeAre section started */}
            <section className="WhoWeAre">
                <Container>
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={12}>
                            <div className="WhoWeAre-image">
                                <div className="Image-One">
                                    <div className="Imag1">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Home/WhoAre/whoare2.png"}
                                            className="who1"
                                        />
                                    </div>
                                </div>
                                <div className="Image-Two">
                                    <div className="image-2">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assests/Home/WhoAre/whoare1.png"}
                                            className="who1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={12}>
                            <div className="Content-Who-are">
                                <h1 className="big-title">Who We Are?</h1>
                                <div className="text-content">
                                    <div className="border-who"></div>
                                    <p className="sub-text">
                                        We specialize in offering top-quality Essential Oils worldwide, earning us the
                                        esteemed title of being the premier destination for these exquisite products.
                                        Rest assured, when you choose to purchase from us, you're selecting unparalleled
                                        quality and craftsmanship. Our unwavering commitment to maintaining the utmost
                                        standards ensures that you can buy with confidence, placing your trust in our
                                        expertise and dedication.
                                    </p>
                                </div>
                                <Link to="/about">
                                <div className="Know">
                                    <Button_Common
                                        className="know-more mini-text"
                                        icon={faArrowRight}
                                        text="KNOW MORE ABOUT US"
                                    ></Button_Common>
                                </div>
                                </Link>
                               
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* WhoWeAre section End */}
        </>
    );
};

export default WhoWeAre;
