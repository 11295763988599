import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../../Button_Common/Button_Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import "./Modals.css";

function Login_Modal(props) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLoginClick = () => {
    props.setSuccessfullModalShow(true); 
    props.onHide(); 

    setTimeout(() => {
      props.setSuccessfullModalShow(false); 
    }, 1000);
  };
  const navigate = useNavigate();

  useEffect(() => {
      let timer;
      if (modalSuccessfullShow) {
          timer = setTimeout(() => {
            setSuccessfullModalShow(false);
             
              navigate("/");
          }, 1000);
      }

      return () => clearTimeout(timer);
  }, [modalSuccessfullShow]);

  const handleCloseModal = () => {
    props.onHide(); // Close the modal
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0">
                  <div className="col-xl-12 col-md-12 col-sm-12">
                    <div className="log_in_form">
                      <Form>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Control
                            type="phone"
                            placeholder="Email / Mobile Number"
                          />
                        </Form.Group>

                        <Form.Group
                          className="password-field-holder mb-3"
                          controlId=""
                        >
                          <Form.Control
                            type={passwordVisible ? "text" : "password"}
                            placeholder="Password"
                          />
                          <div
                            className="eye-icon-holder"
                            onClick={togglePasswordVisibility}
                          >
                            <FontAwesomeIcon
                              className="eye-icon"
                              icon={passwordVisible ? faEye : faEyeSlash}
                            />
                          </div>
                        </Form.Group>

                        <Form.Text
                          className="Forgot_password"
                          onClick={() => {
                            props.setForgetModalShow(true);
                            props.onHide();
                          }}
                        >
                          Forgot password?
                        </Form.Text>

                        <div className="btn-holder">
                          <Common_Button
                            className="SubmitBtn green text"
                            text="Login"
                            onClick={handleLoginClick}
                          />
                        </div>

                        <div className="create_account ">
                          <div className="login_text">
                            <p>
                              You don't have an account?
                              <span
                                className="login_text"
                                onClick={() => {
                                  props.setCreatAccountModalShow(true);
                                  props.onHide();
                                }}
                              >
                                Create account
                              </span>
                            </p>
                          </div>
                        </div>
                      </Form>
                      <div className="log_in_with">
                        <p>or Login with social accounts</p>
                      </div>

                      <div className="social_img_holder">
                        <img
                          className="social_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assests/log_in/google.png"
                          }
                          alt="social_img"
                        />
                      </div>
                      <Link to="/" onClick={handleCloseModal}>
                        <div className="back_to_span">
                          <p>Back to Home {">"}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Success Modal */}
      <Successfull_Modal
        show={props.modalSuccessfullShow}
        onHide={() => props.setSuccessfullModalShow(false)}
        message="Login successful!"
      />
    </>
  );
}

export default Login_Modal;
