import React from 'react'
import CartPage from './CartPage/CartPage'
import SimilarProduct from "./SimilarProduct/SimilarProduct"
const Cart = () => {
  return (
    <>
      <CartPage/>
      <SimilarProduct/>
    </>
  )
}

export default Cart
