import React from "react";
import {Container} from "react-bootstrap";
import "./OilCategory.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Pagination,Navigation,Mousewheel,Keyboard} from "swiper/modules";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {text} from "@fortawesome/fontawesome-svg-core";
const OilCategory = () => {
    const OilCategories = [
        {
            image: process.env.PUBLIC_URL + "/assests/Home/OilCategories/oil1.png",
            text: "CYPRIOL ESSENTIAL OIL",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Home/OilCategories/oil2.png",
            text: "CYPRIOL ESSENTIAL OIL",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Home/OilCategories/oil3.png",
            text: "CYPRIOL ESSENTIAL OIL",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Home/OilCategories/oil4.png",
            text: "CYPRIOL ESSENTIAL OIL",
        },
    ];
    return (
        <>
            <section className="OilCategory">
                <Container>
                    <p class="text">OILS CATEGORY</p>
                    <h1 className="mid-title">
                        Nourishing Nature's Essence, One <br></br>Drop at a Time
                    </h1>
                    <Swiper
                        className="mySwiper"
                        cssMode={true}
                        // navigation={true}
                        pagination={true}
                        mousewheel={true}
                        keyboard={true}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                            },
                            380: {
                                slidesPerView: 1,
                                spaceBetween: 30,
                            },
                            485: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            575: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },

                            768: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 30,
                            },
                            1440: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                            2000: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {OilCategories.map((item, index) => (
                            <SwiperSlide>
                                <div className="Oil-Main-section">
                                    <div className="categories">
                                        <img src={item.image} className="oil-image" />
                                    </div>
                                    <div className="oils-content">
                                        <div className="oil-background">
                                            <button className="btn btn-reverse btn-arrow">
                                                <span>
                                                    {item.text}
                                                    <FontAwesomeIcon icon={faArrowRight} className="icon" />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            </section>
        </>
    );
};

export default OilCategory;
