import React from 'react'
import ProductDetailsPage from './ProductDetailsPage/ProductDetailsPage'
import ProductContent from './ProductContent/ProductContent'
import RelatedProducts from './RelatedProducts/RelatedProducts'

const ProductDetail = () => {
  return (
    <>
      <ProductDetailsPage/>
      <ProductContent/>
      <RelatedProducts/>
    </>
  )
}

export default ProductDetail
