import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Contact.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Banner_Heading_Text from "../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import { Col, Container, Form, Row } from "react-bootstrap";
import {
    faEnvelope,
    faLocationDot,
    faMapLocationDot,
    faPhone,
    faCircleXmark,
    faExpand,
    faMaximize,
    faMinimize,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import Success_New from "../Animation/Success_New/Success_New"; // Ensure you have this component

function Contact() {
    const [phoneValue, setPhoneValue] = useState("");
    const [showMap, setShowMap] = useState(false);
    const handle = useFullScreenHandle();

    const [isMessageSent, setIsMessageSent] = useState(false); // State for showing the success message
    const navigate = useNavigate(); // Use navigate for redirection

    const toggleMap = () => {
        if (showMap && handle.active) {
            handle.exit();
        }
        setShowMap(!showMap);
    };

    const expandMap = () => {
        if (handle.active) {
            handle.exit();
        } else {
            handle.enter();
        }
    };

    const handleSendMessage = () => {
        // Trigger success message and hide form
        setIsMessageSent(true);
        // After 1 second, hide the success message and navigate to home
        setTimeout(() => {
            setIsMessageSent(false); // Optionally hide the success message
            navigate("/"); // Redirect to the home page
        }, 1000); // 1 second delay
    };

    const [breadcrumbItems] = useState([
        { text: "HOME", link: "/" },
        { text: "CONTACT US", link: "/contact", className: "active" },
    ]);

    return (
        <>
            <section className="cont_sectionn">
                <div className="contact_section">
                    <Container>
                        <div className="contact_main">
                            <Banner_Heading_Text headingText={""} breadcrumbItems={breadcrumbItems} />
                            <h2 className="title">Contact Us</h2>
                            <div>
                                <Row>
                                    <Col xxl={9} xl={9} lg={10} md={11} sm={12}>
                                        <div className={`Information-client ${showMap ? "hidden" : ""}`}>
                                            <Row>
                                                <Col
                                                    xxl={8}
                                                    xl={8}
                                                    lg={7}
                                                    md={12}
                                                    xs={12}
                                                    sm={12}
                                                    className="pe-xl-0 pe-xxl-0 pe-lg-0 pe-md-0"
                                                >
                                                    <div className="leftdivv">
                                                        {!isMessageSent ? (
                                                            <>
                                                                <Form>
                                                                    <Form.Group
                                                                        className="mb-4"
                                                                        controlId="exampleForm.ControlInput1"
                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Your Name"
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group
                                                                        className="mb-4"
                                                                        controlId="exampleForm.ControlInput1"
                                                                    >
                                                                        <Form.Control
                                                                            type="email"
                                                                            placeholder="Email"
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group
                                                                        className="mb-4"
                                                                        controlId="exampleForm.ControlInput1"
                                                                    >
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Subject"
                                                                        />
                                                                    </Form.Group>
                                                                    <PhoneInput
                                                                        className="mb-4"
                                                                        country={"in"}
                                                                        value={phoneValue}
                                                                        placeholder="Enter Mobile Number"
                                                                        onChange={setPhoneValue}
                                                                        inputProps={{
                                                                            name: "phone",
                                                                            required: true,
                                                                            autoFocus: true,
                                                                        }}
                                                                    />
                                                                    <Form.Group
                                                                        className="mb-4"
                                                                        controlId="exampleForm.ControlTextarea1"
                                                                    >
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={1}
                                                                            placeholder="Your Message"
                                                                        />
                                                                    </Form.Group>
                                                                </Form>
                                                                <Form className="check">
                                                                    <div className="mb-3">
                                                                        <Form.Check
                                                                            type="checkbox"
                                                                            id={`default-checkbox`}
                                                                            label={`I have read and agree to the Privacy Policy.`}
                                                                        />
                                                                    </div>
                                                                </Form>
                                                                <div className="btndiv">
                                                                    <button
                                                                        className="btnnnn"
                                                                        onClick={handleSendMessage}
                                                                    >
                                                                        Send Message
                                                                    </button>
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className="sent-successfull">
                                                                <div className="success">
                                                                    <h1 className="price-text">
                                                                        Message Sent Successfully!
                                                                    </h1>
                                                                </div>
                                                                <div className="success-lottie">
                                                                    <Success_New />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col
                                                    xxl={4}
                                                    xl={4}
                                                    lg={5}
                                                    md={12}
                                                    sm={12}
                                                    xs={12}
                                                    className="ps-xxl-0 ps-xl-0 ps-lg-0 ps-md-0"
                                                >
                                                    <div className="leftdivv1">
                                                        <div className="d-flex align-items-center information mt-3">
                                                            <div>
                                                                <div className="fonticndiv">
                                                                    <FontAwesomeIcon icon={faPhone} className="icon" />
                                                                </div>
                                                            </div>
                                                            <p className="text mb-0 ms-2">01123968786</p>
                                                        </div>
                                                        <div className="d-flex align-items-center information mt-3">
                                                            <div>
                                                                <div className="fonticndiv">
                                                                    <FontAwesomeIcon icon={faEnvelope} className="icon" />
                                                                </div>
                                                            </div>
                                                            <p className="text mb-0 ms-2">support@mansanutri.com</p>
                                                        </div>
                                                        <div className="d-flex align-items-center information mt-3">
                                                            <div>
                                                                <div className="fonticndiv">
                                                                    <FontAwesomeIcon
                                                                        icon={faLocationDot}
                                                                        className="icon"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p className="text mb-0 ms-2">
                                                                516/4, Patil Nagar, Kashmere Gate Pune, Pune, 411038
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center information mt-3">
                                                            <div>
                                                                <div className="fonticndiv">
                                                                    <FontAwesomeIcon
                                                                        icon={faMapLocationDot}
                                                                        className="icon"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <p
                                                                className="text mb-0 ms-2 text-decoration-underline cursor-pointer"
                                                                onClick={toggleMap}
                                                            >
                                                                Find Us on map
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                        <FullScreen handle={handle}>
                                            <div
                                                className={`map ${!showMap ? "hidden" : "show"} ${
                                                    handle.active ? "fullscreen" : ""
                                                }`}
                                            >
                                                <iframe
                                                    className="gmap_iframe"
                                                    frameBorder="0"
                                                    scrolling="no"
                                                    marginHeight="0"
                                                    marginWidth="0"
                                                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d15133.39075304903!2d73.7810866!3d18.5131838!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1719574302642!5m2!1sen!2sin"
                                                ></iframe>
                                                <div className="close">
                                                    <div className="map-icons">
                                                        <FontAwesomeIcon
                                                            icon={handle.active ? faMinimize : faMaximize}
                                                            className="expand"
                                                            onClick={expandMap}
                                                        />
                                                        <FontAwesomeIcon
                                                            icon={faCircleXmark}
                                                            className="close-icon"
                                                            onClick={toggleMap}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </FullScreen>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    );
}

export default Contact;
