import React, {useState} from "react";
import "./Products.css";
import Common_Banner from "../CommonComponents/Common_Banner/Common_Banner";
import ProductCards from "../CommonComponents/ProductCards/ProductCards";
import Product_Filter from "./Product_Filter/Product_Filter";
import Banner_Heading_Text from "../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import {Col, Row} from "react-bootstrap";

const products = [
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p1.png",
        title: "Black Pepper Essential Oils ",
        price: "00.00",
    },
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p2.png",
        title: "Bergamot Essential Oil",
        price: "00.00",
    },
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
        title: "Bitter Orange Oil ",
        price: "00.00",
    },
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p4.png",
        title: "Carrot Seed Oil ",
        price: "00.00",
    },
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p1.png",
        title: "Black Pepper Essential Oils ",
        price: "00.00",
    },
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p2.png",
        title: "Bergamot Essential Oil",
        price: "00.00",
    },
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
        title: "Bitter Orange Oil ",
        price: "00.00",
    },
    {
        image: process.env.PUBLIC_URL + "/assests/ProductCards/p4.png",
        title: "Carrot Seed Oil ",
        price: "00.00",
    },
];
function Products() {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "HOME", link: "/"},
        {text: "PRODUCTS", link: "/product", className: "active"},
        ,
    ]);
    return (
        <>
            <div className="Products">
                <div className="container">
                    <Row>
                        <Col xxl={3} xl={3} lg={3}>
                            <Banner_Heading_Text headingText={"Products"} breadcrumbItems={breadcrumbItems} />
                            <div className="filter-section">
                                <Product_Filter />
                            </div>
                        </Col>
                        <Col xxl={8} xl={8} lg={8}>
                            <div className="products-card-section">
                                <Row>
                                    {products.map((item, index) => (
                                        <Col xxl={4} xl={4} lg={6} md={6} xs={12} sm={6}>
                                            <ProductCards image={item.image} title={item.title} price={item.price} />
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}

export default Products;
