import React from "react";
import "./QualityProducts.css";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMedal} from "@fortawesome/free-solid-svg-icons";

const QualityProducts = () => {
    return (
        <>
            <section className="QualityProducts">
                <Container>
                    <div className="QualityProducts-Main">
                        <div className="content-Products">
                            <div className="product">
                                <img
                                    src={process.env.PUBLIC_URL + "/assests/AboutUs/logos/quality.png"}
                                    className="icon"
                                />
                                <div className="Content">
                                    <p className="sub-text">Quality Products</p>
                                    <p className="mini-text">
                                        We ensure the product quality <br></br> that is our main goal
                                    </p>
                                </div>
                            </div>
                            <div className="border-main"></div>
                            <div className="product ">
                                <img
                                    src={process.env.PUBLIC_URL + "/assests/AboutUs/logos/product-return.png"}
                                    className="icon"
                                />
                                <div className="Content">
                                    <p className="sub-text">Return</p>
                                    <p className="mini-text">
                                        Return product within 3 days<br></br> for any product you buy
                                    </p>
                                </div>
                            </div>
                            <div className="border-main"></div>
                            <div className="product">
                                <img
                                    src={process.env.PUBLIC_URL + "/assests/AboutUs/logos/technical-support.png"}
                                    className="icon"
                                />
                                <div className="Content">
                                    <p className="sub-text">Online Support</p>
                                    <p className="mini-text">
                                        We ensure the product that <br></br>you can trust easily
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default QualityProducts;
