import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Common_Button from "../../../CommonComponents/Button_Common/Button_Common";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import { Rating } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";

function Rate_Us_Modal(props) {
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);

    const handleSubmit = () => {
        props.onHide();
        setSuccessfullModalShow(true);

       
        setTimeout(() => {
            setSuccessfullModalShow(false);
        }, 1000);
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                className="Modal-holder"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Rate Us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="LogIn">
                        <div className="row bg-color me-0 ms-0">
                            <div className="col-md-12 p-0">
                                <div className="row justify-content-center me-0 ms-0  ">
                                    <div className="col-md-12">
                                        <div className="log_in_form">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Review this Item*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Description*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Title*" />
                                                </Form.Group>

                                                <div className="Rate">
                                                    <p className="mini-text">Give Rate*</p>
                                                    <Rating />
                                                </div>
                                            </Form>
                                            <p className="mini-text">Upload Img*</p>
                                            <div className="camera">
                                                <FontAwesomeIcon icon={faCamera} />
                                            </div>

                                            <div className="btn-holder">
                                                <Common_Button
                                                    className="SubmitBtn"
                                                    text="Submit"
                                                    onClick={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Bottom_Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => {
                    setSuccessfullModalShow(false);
                }}
                text={"Rating Submitted Successfully!"}
            />
        </>
    );
}

export default Rate_Us_Modal;
