import React, { useState } from "react";
import "./AboutBanner.css";
import Breadcrumbs from "../../CommonComponents/Breadcrumbs/Breadcrumbs";
import { Col, Container, Row } from "react-bootstrap";
import Common_Banner from "../../CommonComponents/Common_Banner/Common_Banner";
const AboutBanner = () => {
  const [breadcrumbItems, setbreadcrumbItems] = useState([
    { text: "HOME", link: "/" },
    { text: "ABOUT US", link: "/about" , className:"active"}
    ,
  ]);
  return (
    <>
      <section className="AboutBanner">
        <div className="banner-about">
          <Common_Banner
            bannerImg={
              process.env.PUBLIC_URL + "/assests/AboutUs/Banner/aboutus.png"
            }
            headingText={"About Us"}
            breadcrumbItems={breadcrumbItems}
          />
          <Container>
            <div className="Who-We-Are">
              <Row>
                <Col xxl={6} xl={6} lg={6}>
                  <div className="Image-content">
                    <div className="Main-Img">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/AboutUs/Banner/who.png"
                        }
                        className="Who"
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assests/AboutUs/Banner/About us1.png"
                        }
                        className="Who2"
                      />
                    </div>
                    <div className="content-title">
                      <h1 className="title">Who We Are?</h1>
                    </div>
                  </div>
                </Col>
                <Col xxl={6} xl={6} lg={6}>
                  <div className="Content-Who">
                    <div className="border-who"></div>
                    <p className="mini-text-p">
                      We specialize in offering top-quality Essential Oils
                      worldwide, earning us the esteemed title of being the
                      premier destination for these exquisite products. Rest
                      assured, when you choose to purchase from us, you're
                      selecting unparalleled quality and craftsmanship. Our
                      unwavering commitment to maintaining the utmost standards
                      ensures that you can buy with confidence, placing your
                      trust in our expertise and dedication.
                    </p>
                  </div>
                  <div className="who-content">
                    <p className="mini-text-p mt-2">
                      Our esteemed customers have consistently voiced their
                      trust in us, confidently purchasing our Essential Oils
                      without hesitation. Their satisfaction speaks volumes, as
                      they rely on our reliability and commitment to excellence,
                      consistently finding joy and contentment in their
                      purchases.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
