import React, { useState } from "react";
import "./Banner_Heading_Text.css"
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

function Banner_Heading_Text({ headingText, breadcrumbItems }) {
   
  return (
    
    <>
      <div className="Banner_Heading_Text">
        <div className="content">
        <Breadcrumbs items={breadcrumbItems} />
          <h1 className="headingtext">{headingText}</h1>
        </div>
      </div>
    </>
  );
}

export default Banner_Heading_Text;
