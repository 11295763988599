import React from "react";
import './Terms_And_Condition_Content.css'
import Button_Common from "../../CommonComponents/Button_Common/Button_Common"
function Terms_And_Condition_Content() {
  return (
    <>
      <section className="Privacy_Policy_Content Terms-Condition">
        <div className="container">
          <div className="Privacy_Policy_Content_holder">
            <div className="list-holder">
              <div className="text-holder">
                <p className="mini-text">
                  By consenting to the terms on this site (the “Website”), you
                  are entering into the following Terms and Conditions
                  established with S'illume. PLEASE REVIEW THESE TERMS
                  THOUGHTFULLY. If you disagree, please leave the site and
                  refrain from further use.
                </p>
              </div>

              <div className="text-holder">
                <h3 className="sub-text ">1. Definitions</h3>
                <p className="mini-text">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when a unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>

                <p className="mini-text">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when a unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>

                <p className="mini-text">
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when a unknown printer took a galley of type
                  and scrambled it to make a type specimen book.
                </p>
              </div>

              <div className="text-holder">
                <h3 className="sub-text ">2. Privacy</h3>
                <p className="mini-text">
                  By accessing this Website, you are accepting Mansa Nutri’s
                  Privacy Policy, the details of which can be found at
                  http://s'illume.com/privacy-policy/.
                </p>

                <p className="mini-text">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>




              </div>

              <div className="text-holder">
                <h3 className="sub-text ">3. Orders</h3>
                <p className="mini-text">
                  A customer makes a purchase on the Mansa Nutri Website and either<br></br> - a) provides their own address for direct shipping, or b) provides the email address of the recipient.
                </p>

                <p className="mini-text">
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a unknown printer took a galley of type and scrambled it to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when a unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>


              </div>


              <div className="text-holder">
                <h3 className="sub-text ">4. Payment</h3>
                <p className="mini-text">
                The customer will complete payment upon placing the order, which includes taxes and shipping charges. Payments are handled by Mansa NUtri's third-party payment processor, & the customer is accountable for reviewing all relevant terms and policies. Mansa Nutri bears no responsibility for the actions or oversights of the payment processing entity.
                </p>

               


              </div>
              <div className="Buttons">
                    <Button_Common text="Decline" className="decline"/>
                    <Button_Common text="Accept" className="accept"/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms_And_Condition_Content;
