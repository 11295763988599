import React, { useState, useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import OTP_Modal from "../CommonComponents/Common_Modal/OTP_Modal/OTP_Modal";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./Login_Activity.css";

function Login_Activity() {
    const [showOtpModal, setOtpModal] = useState(false);
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [showEditButton, setShowEditButton] = useState(true);

    const navigate = useNavigate(); 

    // Function to handle OTP submission
    const handleOtpSubmit = () => {
        setOtpModal(true);

        // Simulate OTP verification delay
        setTimeout(() => {
            setOtpModal(false);
            setShowButtons(true);
        }, 1000); // Adjust timeout as needed
    };

    // Function to handle Edit button click
    const handleEditClick = () => {
        setEditMode(true);
        setShowEditButton(false);
    };

    // Function to handle Back button click
    const handleBackClick = () => {
        setOtpModal(false);
        setEditMode(false);
        setShowEditButton(true);
        setShowButtons(false);
    };

    // Handle modal close and navigation
    useEffect(() => {
        if (modalSuccessfullShow) {
            const timer = setTimeout(() => {
                setSuccessfullModalShow(false);
                navigate("/account"); // Navigate to the account page
            }, 3000); // 3000ms = 3 seconds

            return () => clearTimeout(timer); // Clean up the timer on component unmount
        }
    }, [modalSuccessfullShow, navigate]);

    const [breadcrumbItems, setBreadcrumbItems] = useState([
        { text: "HOME", link: "/" },
        { text: "ACCOUNT", link: "/account" },
        { text: "LOGIN ACTIVITY", link: "/" }
    ]);

    return (
        <>
            <section className="Login">
                <Container>
                    <Breadcrumbs items={breadcrumbItems} />
                    <h1 className="mid-title">Login Activity</h1>
                    <div className="Login_Activity Modal-holder">
                        <div className="row justify-content-center">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-xs-12 col-12">
                                <div className="Login_Activity_Form_Holder">
                                    <div className="LogIn">
                                        <div className="row bg-color me-0 ms-0">
                                            <div className="col-md-12 p-0">
                                                <div className="row justify-content-center me-0 ms-0">
                                                    <div className="col-md-12">
                                                        <div className="log_in_form">
                                                            <div className="edit_span_text_holder">
                                                                {showEditButton && !editMode && (
                                                                    <span
                                                                        className="edit_span_text"
                                                                        onClick={handleEditClick}
                                                                    >
                                                                        Edit
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <Form>
                                                                <Form.Group className="mb-3" controlId="">
                                                                    <Form.Control type="text" placeholder=" Name*" />
                                                                </Form.Group>

                                                                <Form.Group className="mb-3" controlId="">
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder=" Name of address*"
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group className="mb-3" controlId="">
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder=" Flat no./House/bldg*"
                                                                    />
                                                                </Form.Group>

                                                                <Form.Group
                                                                    className="password-field-holder mb-3"
                                                                    controlId=""
                                                                >
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder=" Colony/street/landmark"
                                                                    />
                                                                    {editMode && !showButtons && (
                                                                        <div className="eye-icon-holder">
                                                                            <span
                                                                                className="edit_span_text"
                                                                                onClick={handleOtpSubmit}
                                                                            >
                                                                                Verify
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </Form.Group>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-holder yes_no_btn_holder">
                                    {showButtons && (
                                        <>
                                            <Link to="/account">
                                                <Common_Button
                                                    className="SubmitBtn me-3 green text"
                                                    text="CANCEL"
                                                    onClick={() => {
                                                        setEditMode(false);
                                                        setShowEditButton(true);
                                                        setShowButtons(false);
                                                    }}
                                                />
                                            </Link>
                                            <Common_Button
                                                className="YesBtn brown text"
                                                text="SAVE CHANGES"
                                                onClick={() => {
                                                    setSuccessfullModalShow(true);
                                                    setShowEditButton(false);
                                                }}
                                            />
                                        </>
                                    )}
                                    {editMode && !showButtons && (
                                        <p className="text" onClick={handleBackClick}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="icon" /> BACK
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <OTP_Modal
                show={showOtpModal}
                onHide={() => {
                    setOtpModal(false);
                    setEditMode(false);
                    setShowEditButton(true);
                }}
            />

            <Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => {
                    setSuccessfullModalShow(false);
                    setShowEditButton(true);
                }}
                message={"Login Changes are saved Successfully!"}
            />
        </>
    );
}

export default Login_Activity;
