import React, {useState, useEffect} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import "./ReturnOrder.css";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import Return_Reason_Modal from "../CommonComponents/Common_Modal/Return_Reason_Modal/Return_Reason_Modal";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal";
import {useNavigate} from "react-router-dom";

const ReturnOrder = () => {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "HOME", link: "/"},
        {text: "ACCOUNT", link: "/account"},
        {text: "YOUR ORDERS", link: "/", className: "active"},
    ]);

    const orders = [
        {
            image: process.env.PUBLIC_URL + "/assests/Orders/balsam-tolu.png",
            name: "Balsam Tolu (Reconstitution)",
            address: "Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016",
            method: "Online",
            quantity: "1 kg",
            pack: "1",
        },
    ];

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        let timer;
        if (isOpen) {
            timer = setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }

        return () => clearTimeout(timer);
    }, [isOpen]);

    const navigate = useNavigate();
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    const [modalSuccessfullShow1, setSuccessfullModalShow1] = useState(false);
    useEffect(() => {
        if (modalSuccessfullShow) {
            const timer = setTimeout(() => {
                setSuccessfullModalShow(false);
                navigate("/your-order");
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [modalSuccessfullShow, navigate]);

    useEffect(() => {
      if (modalSuccessfullShow1) {
          const timer = setTimeout(() => {
              setSuccessfullModalShow1(false);
              navigate("/your-order");
          }, 1000);

          return () => clearTimeout(timer);
      }
  }, [modalSuccessfullShow1, navigate]);
    return (
        <>
            <section className="ReturnOrder Your-Order">
                <Container>
                    <Breadcrumbs items={breadcrumbItems} />
                    <h1 className="mid-title">Your Orders</h1>
                    <div className="Orders-Main">
                        {orders.map((item, index) => (
                            <div className="ordermain_divv" key={index}>
                                <div className="order_main">
                                    <Row>
                                        <Col xxl={3} xl={3} lg={4} md={12} sm={12} xs={12} className="mb-2">
                                            <div className="image">
                                                <div className="orderlist_imgdiv">
                                                    <img src={item.image} className="orderlist_img" alt="Product" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xxl={9} xl={9} lg={8} md={12} sm={12} xs={12} className="my-auto mb-2">
                                            <div className="order_data">
                                                <Row>
                                                    <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">Product Name:</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">{item.name}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl={3} xl={3} lg={4} md={6} xs={12} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">Name of address:</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={9} xl={9} lg={8} md={6} xs={12} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">{item.address}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">Payment Method:</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={9} xl={9} lg={8} md={6} xs={6} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">{item.method}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">Quantity:</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">{item.quantity}</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} xl={3} lg={4} md={6} xs={6} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">Qty. Of Pack:</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} xl={3} lg={8} md={6} xs={6} sm={6}>
                                                        <div className="orderlist_div">
                                                            <p className="mini-text">{item.pack}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                                                        <div className="orderlist_div">
                                                            <p className="price-text">Total amount:</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                                                        <div className="orderlist_div">
                                                            <p className="price-text">₹. 1,234.00</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} xl={3} lg={4} md={6} sm={6} xs={6}>
                                                        <div className="orderlist_div">
                                                            <p className="price-text">Status:</p>
                                                        </div>
                                                    </Col>
                                                    <Col xxl={3} xl={3} lg={8} md={6} sm={6} xs={6}>
                                                        <div className="orderlist_div">
                                                            <p className="price-text">Paid</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="Reason">
                        <div className="select" onClick={openModal}>
                            <p className="mini-text">Select a reason for Returning</p>
                            <FontAwesomeIcon icon={faAngleRight} />
                            <Return_Reason_Modal isOpen={isOpen} onClose={closeModal} />
                        </div>
                    </div>
                    <div className="cancellation Modal-holder">
                        <p className="text">Could you tell us a reason for cancellation? (optional)</p>

                        <div className="LogIn">
                            <div className="row bg-color me-0 ms-0">
                                <div className="col-md-12 p-0">
                                    <div className="row justify-content-center me-0 ms-0">
                                        <div className="col-xl-12  col-md-12  col-sm-12">
                                            <div className="log_in_form">
                                                <Form>
                                                    <Form.Group className="mb-3" controlId="">
                                                        <Form.Control type="phone" placeholder="Type a reason" />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Button-Main">
                        <Button_Common
                            className="cancel text me-4"
                            text="CANCEL"
                            onClick={() => {
                                setSuccessfullModalShow(true);
                            }}
                        />

                        <Link to="/cancel-order">
                            <Button_Common className="return text" text="SUBMIT"  onClick={() => {
                                setSuccessfullModalShow1(true);
                            }} />
                        </Link>
                    </div>
                </Container>
            </section>
            <Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => {
                    setSuccessfullModalShow(false);
                }}
                message={"Order Cancel Successfully!"}
            />
              <Successfull_Modal
                show={modalSuccessfullShow1}
                onHide={() => {
                    setSuccessfullModalShow1(false);
                }}
                message={"Order Return Successfully!"}
            />
        </>
    );
};

export default ReturnOrder;
