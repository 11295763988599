import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Common_Button from "../../../CommonComponents/Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import "./Get_A_Quote.css";

function Get_A_Quote(props) {
    const [modalShow, setModalShow] = useState(false);

    const handleSubmit = () => {
        setModalShow(true);
        props.onHide();

        // Automatically close success modal after 3 seconds
        setTimeout(() => {
            setModalShow(false);
        }, 1000);
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                className="Modal-holder"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Get A Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="LogIn">
                        <div className="row bg-color me-0 ms-0">
                            <div className="col-md-12 p-0">
                                <div className="row justify-content-center me-0 ms-0">
                                    <div className="col-md-12">
                                        <div className="log_in_form">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Enter Company Name*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Enter Contact Person Name*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Enter Email Id*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Enter Product Name or Code*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Quantity" />
                                                </Form.Group>
                                            </Form>

                                            <div className="btn-holder">
                                                <Common_Button
                                                    className="SubmitBtn"
                                                    text="Submit"
                                                    onClick={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Bottom_Successfull_Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                text="Quote Sent Successfully!"
            />
        </>
    );
}

export default Get_A_Quote;
