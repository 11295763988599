import React from "react";
import "./Banner.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Navigation, Pagination, Mousewheel, Keyboard} from "swiper/modules";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common"
function Banner() {
    const banners = [
        {
            image: process.env.PUBLIC_URL + "/assests/Home/Banner/banner-home.png",
        },
        {
            image: process.env.PUBLIC_URL +  "/assests/Home/Banner/banner-home.png",
        },
        {
            image: process.env.PUBLIC_URL +  "/assests/Home/Banner/banner-home.png",
        },
      
    ];
    return (
        // Home Banner Started

        <section className="Home-Banner">
            <div className="container-fluid p-0">
                <Swiper
                    cssMode={true}
                    navigation={true}
                    pagination={true}
                    mousewheel={true}
                    keyboard={true}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="mySwiper"
                >
                    {banners.map((item, index) => (
                        <SwiperSlide key={index}>
                          <div className="image-home">
                              <img src={item.image} className="banner-image" />
                              <div className="content-banner">
                                 <div className="main-content">
                                   <p className="text">We Like to See you Shine</p>
                                   <p className="title">Purest Quality of <br></br>Essentials Oil</p>
                                   <Button_Common text="Explore" className="explore mt-2"/>
                                 </div>
                              </div>
                          </div>
                          <div className="image-overlay"></div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
        //  Home Banner End
    );
}

export default Banner;
