

import React, { useEffect } from "react";
import Return_Policy_Banner from "./Return_Policy_Banner/Return_Policy_Banner";
import Return_Policy_Content from "./Return_Policy_Content/Return_Policy_Content";

function Return_Policy() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  return (
    <>
      <Return_Policy_Banner />
      <Return_Policy_Content />
    </>
  );
}

export default Return_Policy;
