import React, { useState } from "react";
import {Col, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import Edit_Address_Modal from "../CommonComponents/Common_Modal/Edit_Address_Modal/Edit_Address_Modal";
const Address = (props) => {
    const options = [
        {value: "chocolate", label: "Chocolate"},
        {value: "strawberry", label: "Strawberry"},
        {value: "vanilla", label: "Vanilla"},
    ];
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    return (
        <>
            <section className="Address">
                <Row>
                    <Col xxl={4} xl={4} lg={12} className="right_border">
                        <div className="Main-address">
                            <div className="Edit">
                                <div className="image-map">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assests/Account/a1.png"}
                                        className="map"
                                        alt="Mansa Nutri Logo"
                                    />
                                    <div className="circle">
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </div>
                                </div>
                                <div className="content">
                                    <p className="sub-text">Home</p>
                                    <p className="text">John Doe</p>
                                    <p className="text">
                                        E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703
                                    </p>
                                </div>
                                <div className="button">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                        />
                                    </div>
                                    <p className="text edit"  onClick={() => setSuccessfullModalShow(true)}>Edit</p>
                                </div>
                            </div>
                        </div>
                        <div className="Main-address">
                            <div className="Edit">
                                <div className="image-map">
                                    <img
                                        src={process.env.PUBLIC_URL + "/assests/Account/a1.png"}
                                        className="map"
                                        alt="Mansa Nutri Logo"
                                    />
                                    <div className="circle">
                                        <FontAwesomeIcon icon={faLocationDot} />
                                    </div>
                                </div>
                                <div className="content">
                                    <p className="sub-text">Home</p>
                                    <p className="text">John Doe</p>
                                    <p className="text">
                                        E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703
                                    </p>
                                </div>
                                <div className="button">
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                        />
                                    </div>
                                    <p className="text edit"  onClick={() => setSuccessfullModalShow(true)}>Edit</p>
                                    <Edit_Address_Modal
                                                show={modalSuccessfullShow}
                                                onHide={() => setSuccessfullModalShow(false)}
                                            />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xxl={8} xl={8} lg={12}>
                        <div className="Address-Content">
                            <div className="main-heading-text">
                                <div className="mb-3">
                                    <Form.Check type="radio" name="name" label="Add new address" />
                                </div>
                            </div>
                            <Form>
                                <Form.Group as={Row} className="mb-3" controlId="formAddressName">
                                    <Form.Label column xxl="3" xl="3" lg="3" md="3" sm="12" xs="12">
                                        Name of address*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Select
                                            classNamePrefix="selectOptions"
                                            options={options}
                                            placeholder="--- please select ---"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formFlatNo">
                                    <Form.Label column xxl="3" xl="3" lg="3" md="3" sm="12" xs="12">
                                        Flat no./House/bldg*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Flat no./House/bldg" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formColony">
                                    <Form.Label column xxl="3" xl="3" lg="3" md="3" sm="12" xs="12">
                                        Colony/street/landmark
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Colony/street/landmark" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formZipCode">
                                    <Form.Label column xxl="3" xl="3" lg="3" md="3" sm="12" xs="12">
                                        Zip code*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Zip code" />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formMobileNumber">
                                    <Form.Label column xxl="3" xl="3" lg="3" md="3" sm="12" xs="12">
                                        Mobile number*
                                    </Form.Label>
                                    <Col xxl="9" xl="9" lg="9" md="9" sm="12" xs="12">
                                        <Form.Control type="text" placeholder="Mobile number" />
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <div className="  apply_now_btn">
                    {/* <Button_Common onClick={props.prevStep} className="back-btn me-3 text"  text="PREVIOUS">
                        Back
                    </Button_Common> */}

                    <Button_Common type="button" onClick={props.nextStep} className="tabs-btn text green" text="SAVE & NEXT">
                        Next
                    </Button_Common>
                </div>
            </section>
        </>
    );
};

export default Address;
