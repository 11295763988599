import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";

function OTP_Modal(props) {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        className="Modal-holder"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="LogIn">
            <div className="row bg-color me-0 ms-0">
              {/* Login Form */}
              <div className="col-md-12 p-0">
                <div className="row justify-content-center me-0 ms-0">
                  <div className="col-xl-12  col-md-12  col-sm-12">
                    <div className="log_in_form">
                      <div className="heading-holder">
                        <p>OTP has been Sent to the Email / Mobile Number</p>
                      </div>

                      <Form>
                        <div className="otp-holder">
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                          <div className="otp-text-holder">
                            <Form.Group className="mb-3" controlId="">
                              <Form.Control type="text" />
                            </Form.Group>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default OTP_Modal;
