import React, { useState, useEffect } from "react";
import "./ProductCards.css";
import Heart_Component from "../Heart_Component/Heart_Component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Bottom_Successfull_Modal from '../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal';

const ProductCards = ({ image, title, price }) => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        let timer;
        if (showModal) {
            timer = setTimeout(() => {
                setShowModal(false); 
                navigate("/product-detail"); 
            }, 1000); 
        }

      
        return () => clearTimeout(timer);
    }, [showModal, navigate]);

    return (
        <>
            <section className="ProductCards">
                <div className="Product-Card">
                    <Link to="/product-detail">
                        <div className="Product-Image">
                            <img src={image} className="card-images" alt={title} />
                        </div>
                        <div className="heart-icon">
                            <Heart_Component />
                        </div>
                    </Link>
                    <div className="content-product">
                        <p className="product-text">{title}</p>
                    </div>
                    <div className="price-product" onClick={handleShowModal}>
                        <p className="price-text">₹ {price}</p>
                        <FontAwesomeIcon icon={faCartShopping} className="icon" />
                    </div>
                </div>
                <Bottom_Successfull_Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    text="Item added to cart successfully!"
                />
            </section>
        </>
    );
};

export default ProductCards;
