import React, { useState, useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import Common_Button from "../../../CommonComponents/Button_Common/Button_Common";
import Bottom_Successfull_Modal from "../Bottom_Successfull_Modal/Bottom_Successfull_Modal";

function Edit_Address_Modal(props) {
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);

  
    const handleSubmit = () => {
        setModalShow(true); 
        props.onHide(); 
    };

    useEffect(() => {
    
        let timer;
        if (modalShow) {
            timer = setTimeout(() => {
                setModalShow(false);
            }, 2000); 
        }
        return () => clearTimeout(timer); 
    }, [modalShow]);

    return (
        <>
            <Modal
                {...props}
                size="md"
                className="Modal-holder"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Edit Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="LogIn">
                        <div className="row bg-color me-0 ms-0">
                            <div className="col-md-12 p-0">
                                <div className="row justify-content-center me-0 ms-0  ">
                                    <div className="col-md-12">
                                        <div className="log_in_form">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder=" Name*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder=" Name of address*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder=" Flat no./House/bldg*" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder=" Colony/street/landmark" />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder=" Zipcode*" />
                                                </Form.Group>
                                            </Form>

                                            <div className="btn-holder">
                                                <Common_Button
                                                    className="SubmitBtn"
                                                    text="Submit"
                                                    onClick={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Render the Bottom_Successfull_Modal with modalShow state */}
            <Bottom_Successfull_Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                text="Address Changed Successfully!"
            />
        </>
    );
}

export default Edit_Address_Modal;
