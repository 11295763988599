import "./banner/Banner.css";
import Banner from "./banner/Banner";
import WhoWeAre from "./WhoWeAre/WhoWeAre";
import OilCategory from "./OilCategory/OilCategory";
import Yoga from "./Yoga/Yoga";
import TrendingProducts from "./TrendingProducts/TrendingProducts";

function Home() {
  return (
    <>
      <Banner />
      <WhoWeAre/>
      <OilCategory/>
      <Yoga/>
      <TrendingProducts/>
    </>
  );
}

export default Home;
