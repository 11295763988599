import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { useNavigation } from 'react-navi';  // Assuming you're using Navi for navigation
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Successfull_Modal from "../Successfull_Modal/Successfull_Modal";
import Common_Button from "../../Button_Common/Button_Common";

function Register_Form_Modal(props) {
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    const [loginModalShow, setLoginModalShow] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigation = useNavigation();  // Use navigation hook from Navi

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const navigate = useNavigate();
    // useEffect(() => {
    //     if (modalSuccessfullShow) {
    //         setTimeout(() => {
    //             setSuccessfullModalShow(false);
    //             navigation.navigate('/');
    //         }, 3000);
    //     }
    // }, [modalSuccessfullShow, navigation]);
    useEffect(() => {
        let timer;
        if (modalSuccessfullShow) {
            timer = setTimeout(() => {
                setSuccessfullModalShow(false);
                
                navigate("/");
            }, 1000); 
        }

        return () => clearTimeout(timer);
    }, [modalSuccessfullShow]);
    return (
        <>
            <Modal
                {...props}
                size="md"
                className="Modal-holder"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Register</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="LogIn">
                        <div className="row bg-color me-0 ms-0">
                            <div className="col-md-12 p-0">
                                <div className="row justify-content-center me-0 ms-0">
                                    <div className="col-md-12">
                                        <div className="log_in_form">
                                            <Form>
                                                <Form.Group className="mb-3" controlId="">
                                                    <Form.Control type="text" placeholder="Full Name" />
                                                </Form.Group>
                                                <div className="row">
                                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                                        <Form.Group className="mb-3" controlId="">
                                                            <Form.Control type="email" placeholder="Email" />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                                        <Form.Group className="mb-3" controlId="">
                                                            <Form.Control type="text" placeholder="Mobile" />
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                                <Form.Group className="password-field-holder mb-3" controlId="">
                                                    <Form.Control
                                                        type={passwordVisible ? "text" : "password"} // Toggle password visibility
                                                        placeholder="Password"
                                                    />
                                                    <div className="eye-icon-holder" onClick={togglePasswordVisibility}>
                                                        <FontAwesomeIcon
                                                            className="eye-icon"
                                                            icon={passwordVisible ? faEye : faEyeSlash}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </Form>

                                            <div className="btn-holder">
                                                <Common_Button
                                                    className="SubmitBtn green text"
                                                    text="Create"
                                                    onClick={() => {
                                                        props.onHide();
                                                        setSuccessfullModalShow(true);
                                                    }}
                                                />
                                            </div>

                                            <div className="create_account">
                                                <div className="login_text">
                                                    <p>
                                                        Already have an account?
                                                        <span
                                                            className="login_text"
                                                            onClick={() => {
                                                                props.onHide();
                                                                setLoginModalShow(true);
                                                            }}
                                                        >
                                                            Login
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="log_in_with">
                                                <p>or register with social accounts</p>
                                            </div>

                                            <div className="social_img_holder">
                                                <img
                                                    className="social_img"
                                                    src={process.env.PUBLIC_URL + "/assests/log_in/google.png"}
                                                    alt="social_img"
                                                />
                                            </div>

                                            <div className="back_to_span">
                                                <Link to={"/"}>
                                                    <p>Back to Home {">"} </p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => {
                    setSuccessfullModalShow(false);
                }}
                message={"Signup Successfully!"}
            />

            <Successfull_Modal
                show={loginModalShow}
                onHide={() => {
                    setLoginModalShow(false);
                }}
                message={"Login Successfully!"}
            />
        </>
    );
}

export default Register_Form_Modal;
