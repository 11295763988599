import React, {useState, useRef} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import Blog_card from "../CommonComponents/Blog_card/Blog_card";
import Banner_Heading_Text from "../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import Paginations from "../CommonComponents/Paginations/Paginations";
import "./Blog.css";
import RecentBlogs from "../CommonComponents/RecentBlogs/RecentBlogs";

function Blog() {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "HOME", link: "/"},
        {text: "BLOGS", link: "/blog", className: "active"},
    ]);

    const [showRecentFeeds, setShowRecentFeeds] = useState(false);
    const recentFeedsRef = useRef(null);

    const blogcard = [
        {
            image: process.env.PUBLIC_URL + "/assests/Blogs/blog1.png",
            title: "Black Pepper Essential Oils",
            price: "00.00",
          },
          {
            image: process.env.PUBLIC_URL + "/assests/Blogs/blog2.png",
            title: "Bergamot Essential Oil",
            price: "00.00",
          },
          {
            image: process.env.PUBLIC_URL + "/assests/Blogs/blog3.png",
            title: "Bitter Orange Oil",
            price: "00.00",
          },
          {
            image: process.env.PUBLIC_URL + "/assests/Blogs/blog4.png",
            title: "Carrot Seed Oil",
            price: "00.00",
          },
          {
            image: process.env.PUBLIC_URL + "/assests/Blogs/blog1.png",
            title: "Black Pepper Essential Oils",
            price: "00.00",
          },
          {
            image: process.env.PUBLIC_URL + "/assests/Blogs/blog2.png",
            title: "Bergamot Essential Oil",
            price: "00.00",
          },
          {
            image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
            title: "Bitter Orange Oil",
            price: "00.00",
          },
          {
            image: process.env.PUBLIC_URL + "/assests/Blogs/blog3.png",
            title: "Carrot Seed Oil",
            price: "00.00",
          },
    ];

  
    return (
        <section className="Blog">
            <Container>
                <Row>
                    <Col xxl={8} xl={8} lg={8} md={12}>
                        <Banner_Heading_Text headingText={"Essentials Blogs"} breadcrumbItems={breadcrumbItems} />
                        <div className="Blog_card_Section">
                            <Row>
                                {blogcard.map((item, index) => (
                                    <Col key={index} xxl={6} xl={6} lg={6} md={6} sm={12}>
                                        <Blog_card image={item.image} title={item.title} date={item.date} />
                                    </Col>
                                ))}
                            </Row>
                            <Paginations />
                        </div>
                    </Col>
                    
                        <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
                           <RecentBlogs/>
                        </Col>
                   
                </Row>
            </Container>
        </section>
    );
}

export default Blog;
