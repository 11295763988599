import React from "react";
import "./TrendingProducts.css";
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import ProductCards from "../../CommonComponents/ProductCards/ProductCards";
import Pagination from "react-bootstrap/Pagination";
import Paginations from "../../CommonComponents/Paginations/Paginations"
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TrendingProducts = () => {
  

    const Whislist = [
        {
            image: process.env.PUBLIC_URL + "/assests/ProductCards/p1.png",
            title: "Black Pepper Essential Oils ",
            price: "00.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/ProductCards/p2.png",
            title: "Bergamot Essential Oil",
            price: "00.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
            title: "Bitter Orange Oil ",
            price: "00.00",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/ProductCards/p4.png",
            title: "Carrot Seed Oil ",
            price: "00.00",
        },
    ];
    return (
        <>
            {/* TrendingProducts css started */}

            <section className="TrendingProducts">
                <Container>
                    <p class="text">TRENDING PRODUCT</p>
                    <h1 className="mid-title">Floral's Hot Selling Products</h1>
                    <div className="Tab-Section">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="d-flex flex-column  ">
                                <Nav variant="pills" className="justify-content-center">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">CATEGORY 1</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">CATEGORY 2</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">CATEGORY 3</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <Row className="">
                                            {Whislist.map((item, index) => (
                                                <Col xxl={3} xl={4} lg={4} md={4} sm={6} xs={6} key={index}>
                                                    <ProductCards image={item.image} title={item.title} price={item.price}/>
                                                </Col>
                                            ))}
                                           <Paginations/>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                    <Row className="">
                                            {Whislist.map((item, index) => (
                                                <Col xxl={3} xl={4} lg={4} md={4} sm={6} xs={6} key={index}>
                                                    <ProductCards image={item.image} title={item.title}  price={item.price}/>
                                                </Col>
                                            ))}
                                            <Paginations/>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                    <Row className="">
                                            {Whislist.map((item, index) => (
                                                <Col xxl={3} xl={4} lg={4} md={4} sm={6} xs={6} key={index}>
                                                    <ProductCards image={item.image} title={item.title} />
                                                </Col>
                                            ))}
                                            <Paginations/>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Container>
            </section>

            {/* TrendingProducts css End */}
        </>
    );
};

export default TrendingProducts;
