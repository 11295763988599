import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import Button_Common from "../CommonComponents/Button_Common/Button_Common";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal";
import Permision_Delete_Modal from "../CommonComponents/Common_Modal/Permision_Delete_Modal/Permision_Delete_Modal";
import {useNavigate} from "react-router-dom";
const OrderReview = (props) => {
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const [deletepermissionShow, setdeletepermissionShow] = React.useState(false);
    const orders = [
        {
            image: process.env.PUBLIC_URL + "/assests/Orders/BAY-LEAF-OIL.png",
            name: "Balsam Tolu (Reconstitution)",
            Quantity: "1 kg",
            pack: "1",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Orders/balsam-tolu.png",
            name: "Balsam Tolu (Reconstitution)",
            Quantity: "1 kg",
            pack: "1",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Orders/shutterstock.png",
            name: "Balsam Tolu (Reconstitution)",
            Quantity: "1 kg",
            pack: "1",
        },
        {
            image: process.env.PUBLIC_URL + "/assests/Orders/BAY-LEAF-OIL.png",
            name: "Balsam Tolu (Reconstitution)",
            Quantity: "1 kg",
            pack: "1",
        },
    ];

    const handlePlaceOrder = () => {
        setModalShow(true);

        setTimeout(() => {
            setModalShow(false);
            navigate("/your-order");
        }, 2000);
    };

    return (
        <>
            <section className="OrderReview">
                <div className="Address">
                    <Form.Check type="checkbox" label="Select All" />
                    <Row className="justify-content-center mt-5">
                        <Col xxl={7} xl={8} lg={12} md={12}>
                            {orders.map((item, index) => (
                                <div className="Deatils-address" key={index}>
                                    <Form.Check type="checkbox" />
                                    <div className="OrderDetails">
                                        <div className="Details">
                                            <Row>
                                                <Col xxl={3} xl={3} lg={3} md={3} xs={12} sm={12}>
                                                    <div className="main-cart">
                                                        <div className="image-product">
                                                            <img
                                                                src={item.image}
                                                                alt="product"
                                                                className="payment-logo"
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={7} xl={8} lg={7} md={7} xs={12} sm={12}>
                                                    <div className="content">
                                                        <Row>
                                                            <Col xxl={5} xl={4} lg={4} md={4} xs={4} sm={4}>
                                                                <p className="mini-text">Product Name:</p>
                                                            </Col>
                                                            <Col xxl={7} xl={8} lg={8} md={8} xs={8} sm={8}>
                                                                <p className="mini-text">{item.name}</p>
                                                            </Col>
                                                            <Col xxl={5} xl={4} lg={4} md={4} xs={4} sm={4}>
                                                                <p className="mini-text">Quantity:</p>
                                                            </Col>
                                                            <Col xxl={7} xl={8} lg={8} md={8} xs={8} sm={8}>
                                                                <p className="mini-text">{item.Quantity}</p>
                                                            </Col>
                                                            <Col xxl={5} xl={4} lg={4} md={4} xs={4} sm={4}>
                                                                <p className="mini-text">Qty. Of Pack:</p>
                                                            </Col>
                                                            <Col xxl={7} xl={8} lg={8} md={8} xs={8} sm={8}>
                                                                <p className="mini-text">{item.pack}</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                                <Col xxl={2} xl={1} lg={2} md={1} xs={12} sm={12}>
                                                    <div className="Delete-order">
                                                        <div className="delete-main">
                                                            <FontAwesomeIcon
                                                                icon={faTrash}
                                                                className="icon"
                                                                onClick={() => setdeletepermissionShow(true)}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Col>
                        <Col xxl={5} xl={4} lg={7} md={10}>
                            <div className="Order-payment">
                                <div className="total-amount">
                                    <Row>
                                        <Col xxl={4} xl={6} lg={4} md={4}>
                                            <p className="mini-text">Price Item(3):</p>
                                        </Col>
                                        <Col xxl={8} xl={6} lg={8} md={8}>
                                            <p className="mini-text">₹. 9,990.00</p>
                                        </Col>
                                        <Col xxl={4} xl={6} lg={4} md={4}>
                                            <p className="mini-text">Discount:</p>
                                        </Col>
                                        <Col xxl={8} xl={6} lg={8} md={8}>
                                            <p className="mini-text">-₹.7877</p>
                                        </Col>
                                        <Col xxl={4} xl={6} lg={4} md={4}>
                                            <p className="mini-text">Payment Method:</p>
                                        </Col>
                                        <Col xxl={8} xl={6} lg={8} md={8}>
                                            <p className="mini-text">Online</p>
                                        </Col>
                                        <Col xxl={4} xl={6} lg={4} md={4}>
                                            <p className="mini-text">Delivery charges:</p>
                                        </Col>
                                        <Col xxl={8} xl={6} lg={8} md={8}>
                                            <p className="mini-text">600</p>
                                        </Col>
                                        <Col xxl={4} xl={6} lg={4} md={4}>
                                            <p className="mini-text">Name of address:</p>
                                        </Col>
                                        <Col xxl={8} xl={6} lg={8} md={8}>
                                            <p className="mini-text">
                                                Akshya Nagar 1st Block 1st Cross, Rammurthy nagar, Bangalore-560016
                                            </p>
                                        </Col>

                                        <Col xxl={4} xl={4} lg={4} md={4}>
                                            <p className="price-text ">Total amount:</p>
                                        </Col>
                                        <Col xxl={8} xl={8} lg={8} md={8}>
                                            <p className="price-text ">₹. 9,990.00</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="place-order mt-4">
                                    <Button_Common className="order green" text="PLACE ORDER" onClick={handlePlaceOrder} />
                                </div>
                                <Successfull_Modal
                                    message="Your Order Has Been Placed Successfully!"
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </div>
                        </Col>
                    </Row>
                    {/* <div className="  apply_now_btn">
                        <Button_Common onClick={props.prevStep} className="back-btn me-3 text brown" text="BACK">
                            Back
                        </Button_Common>

                        <Button_Common
                            type="button"
                            onClick={props.nextStep}
                            className="tabs-btn text green"
                            text="SAVE & NEXT"
                        >
                            Next
                        </Button_Common>

                       
                    </div> */}
                    <Permision_Delete_Modal
                            show={deletepermissionShow}
                            onHide={() => setdeletepermissionShow(false)}
                        />
                </div>
            </section>
        </>
    );
};

export default OrderReview;
