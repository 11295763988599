import React from "react";
import Pagination from "react-bootstrap/Pagination";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './Paginations.css'
const Paginations = ({currentPage, onPageChange}) => {
    const totalPages = 10; // Total number of pages

    const handlePrev = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };
    return (
        <>
        <div className="Pagination">
        <Pagination>
                <Pagination.Prev onClick={handlePrev} disabled={currentPage === 1}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Pagination.Prev>

                <Pagination.Item active={currentPage === 1} onClick={() => onPageChange(1)}>
                    1
                </Pagination.Item>
                <Pagination.Item active={currentPage === 2} onClick={() => onPageChange(2)}>
                    2
                </Pagination.Item>
                <Pagination.Item active={currentPage === 3} onClick={() => onPageChange(3)}>
                    3
                </Pagination.Item>

                <Pagination.Ellipsis />

                <Pagination.Item active={currentPage === 10} onClick={() => onPageChange(10)}>
                    10
                </Pagination.Item>

                <Pagination.Next onClick={handleNext} disabled={currentPage === totalPages}>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Pagination.Next>
            </Pagination>
        </div>
            
        </>
    );
};

export default Paginations;
