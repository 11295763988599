import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./ProductDetailsPage.css";
import Banner_Heading_Text from "../../Banner_Heading_Text/Banner_Heading_Text";
import IncrementDecrement from "../../IncreamentDecreament/IncreamentDecreament";
import Heart_Component from "../../Heart_Component/Heart_Component";
import ButtonCommon from "../../Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import Bottom_Successfull_Modal from "../../Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";
import {useNavigate} from "react-router-dom";
import Get_A_Quote from "../../Common_Modal/Get_A_Quote/Get_A_Quote";
function ProductDetailsPage() {
    const [breadcrumbItems, setBreadcrumbItems] = useState([
        {text: "HOME", link: "/"},
        {text: "PRODUCTS", link: ""},
        {text: "BALSAM TOLU (RECONSTITUTION)", link: "", className: "active"},
    ]);
    // const [modalShow, setModalShow] = React.useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (modalShow) {
            const timer = setTimeout(() => {
                setModalShow(false);
                navigate("/cart");
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [modalShow, navigate]);
    return (
        <>
            <section className="ProductDetailsPage">
                <Container>
                    <Banner_Heading_Text breadcrumbItems={breadcrumbItems} />
                    <Row>
                        <Col xxl={5} xl={5} lg={6}>
                            <div className="Image-product">
                                <img
                                    src={process.env.PUBLIC_URL + "/assests/ProductCards/balsam-tolu.png"}
                                    className="product-detail"
                                    alt="Mansa Nutri Logo"
                                />
                                <div className="heart">
                                    <Heart_Component />
                                </div>
                            </div>
                        </Col>
                        <Col xxl={7} xl={7} lg={6}>
                            <div className="Product_detail-Content">
                                <h1 className="product-detail">Balsam Tolu (Reconstitution)</h1>
                                <div className="Quantity">
                                    <div className="headins"></div>
                                    <div className="Price">
                                        <table class="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th className="pack">Quantity</th>
                                                    <th className="pack main">fgfgfg</th>
                                                    <th className="pack">Price Per Unit</th>
                                                    <th className="pack">Qty. of Pack</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text">1kg</td>
                                                    <td>
                                                        <div className="dashed"></div>
                                                    </td>
                                                    <td className="text">₹ 00.00</td>
                                                    <td className="text">
                                                        <IncrementDecrement />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text">1kg</td>
                                                    <td>
                                                        <div className="dashed"></div>
                                                    </td>
                                                    <td className="text">₹ 00.00</td>
                                                    <td className="text">
                                                        <IncrementDecrement />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text">1kg</td>
                                                    <td>
                                                        <div className="dashed"></div>
                                                    </td>
                                                    <td className="text">₹ 00.00</td>
                                                    <td className="text">
                                                        <IncrementDecrement />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text">1kg</td>
                                                    <td>
                                                        <div className="dashed"></div>
                                                    </td>
                                                    <td className="text">₹ 00.00</td>
                                                    <td className="text">
                                                        <IncrementDecrement />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="text">1kg</td>
                                                    <td>
                                                        <div className="dashed"></div>
                                                    </td>
                                                    <td className="text">₹ 00.00</td>
                                                    <td className="text">
                                                        <IncrementDecrement />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <Row>
                                        <Col xxl={8}>
                                            <div className="cart-btn">
                                                <ButtonCommon
                                                    text="ADD SAMPLE TO CART"
                                                    className="sample green"
                                                    onClick={() => setModalShow(true)}
                                                />
                                                <Bottom_Successfull_Modal
                                                    text="Sample added to cart successfully!"
                                                    show={modalShow}
                                                    onHide={() => setModalShow(false)}
                                                />
                                                <ButtonCommon
                                                    text="ADD TO CART"
                                                    className="cart brown"
                                                    icon={faCartShopping}
                                                    onClick={() => setModalShow(true)}
                                                >
                                                   
                                                </ButtonCommon>
                                                
                                            </div>
                                        </Col>
                                        <Col xxl={4}>
                                            <div className="main-text" onClick={() => setSuccessfullModalShow(true)}>
                                                <p className="text">Get a Quote</p>
                                            </div>
                                            <Get_A_Quote
                                                show={modalSuccessfullShow}
                                                onHide={() => setSuccessfullModalShow(false)}
                                            />
                                        </Col>
                                    </Row>
                                    <p className="mini-text">
                                        Pricing is subject to change, & product availability is not guaranteed.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default ProductDetailsPage;
