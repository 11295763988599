import React, {useState} from "react";
import "./Product_Filter.css";
import {Form} from "react-bootstrap";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
function Product_Filter() {
    const [value, setValue] = useState(10);
    const minValue = 500;
    const maxValue = 50000;

    const [isOpen, setIsOpen] = useState(false);

    const toggleFilter = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div className="Product_Filter desktop-view">
                <div className="heading-holder">
                    <h3 className="sub-text">Products by Category</h3>
                </div>

                <div className="checkbox-holder">
                    <Form className="checkbox-holder-section">
                        <Form.Check type="checkbox" name="group1" label="Essential Oil (78)" />

                        <Form.Check type="checkbox" name="group1" label="Carrier Oil (45)" />

                        <Form.Check type="checkbox" name="group1" label="Phytochemicals (98)" />
                    </Form>
                </div>

                <hr className="hr-line-holder" />

                <div className="price-range-section">
                    <div className="heading-holder">
                        <h3 className="sub-text">Price Range</h3>
                    </div>
                    <div className="range-bar">
                        <div className="custom-slider-container">
                            <InputRange
                                draggableTrack
                                minValue={minValue}
                                maxValue={maxValue} 
                                formatLabel={(value) => `₹${value}`} 
                                value={value}
                                onChange={(value) => setValue(value)}
                                onChangeComplete={(value) => console.log(value)}
                            />
                            <div className="slider-value">
                                <div className="slide">
                                    <span className="text">₹{minValue}</span>
                                    <span className="text">₹{maxValue}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Mobile-View">
                <div className="product-filter-container">
                    {/* Filter icon to toggle visibility */}
                    <div className="filter-icon" onClick={toggleFilter}>
                        <FontAwesomeIcon icon={faFilter} />
                    </div>

                    {/* Conditional rendering of filter section based on isOpen state */}
                    {isOpen && (
                        <div className="Product_Filter">
                            <div className="heading-holder">
                                <h3 className="sub-text">Products by Category</h3>
                            </div>

                            <div className="checkbox-holder">
                                <Form className="checkbox-holder-section">
                                    <Form.Check type="checkbox" name="group1" label="Essential Oil (78)" />

                                    <Form.Check type="checkbox" name="group1" label="Carrier Oil (45)" />

                                    <Form.Check type="checkbox" name="group1" label="Phytochemicals (98)" />
                                </Form>
                            </div>

                            <hr className="hr-line-holder" />

                            <div className="price-range-section">
                                <div className="heading-holder">
                                    <h3 className="sub-text">Price Range</h3>
                                </div>
                                <div className="range-bar">
                                    <div className="custom-slider-container">
                                        <InputRange
                                            draggableTrack
                                            minValue={minValue} // Setting the minimum value
                                            maxValue={maxValue} // Setting the maximum value
                                            formatLabel={(value) => `₹${value}`} // Formatting label with rupee symbol
                                            value={value}
                                            onChange={(value) => setValue(value)}
                                            onChangeComplete={(value) => console.log(value)}
                                        />
                                        <div className="slider-value">
                                            <div className="slide">
                                                <span className="text">₹{minValue}</span>
                                                <span className="text">₹{maxValue}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Product_Filter;
