import React, {useEffect, useState} from "react";
import "./CartPage.css";
import Banner_Heading_Text from "../../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import {Col, Container, Form, Row} from "react-bootstrap";
import IncreamentDecreament from "../../CommonComponents/IncreamentDecreament/IncreamentDecreament";
import Button_Common from "../../CommonComponents/Button_Common/Button_Common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Bottom_Successfull_Modal from "../../CommonComponents/Common_Modal/Bottom_Successfull_Modal/Bottom_Successfull_Modal";

const CartPage = () => {
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "HOME", link: "/"},
        {text: "CART", link: "/cart", className: "active"},
    ]);

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        let timer;
        if (showModal) {
            timer = setTimeout(() => {
                setShowModal(false); 
                // navigate("/product-detail"); 
            }, 1000); 
        }

      
        return () => clearTimeout(timer);
    }, [showModal, navigate]);

    return (
        <>
            <section className="CartPage">
                <Container>
                    <Banner_Heading_Text headingText={""} breadcrumbItems={breadcrumbItems} />
                    <div className="main-cart">
                    <Form.Check type="checkbox" label="Select All" />
                        <Row className="justify-content-center mt-4">
                            <Col xxl={9} xl={9} lg={12}>
                                <div className="Main-Table">
                                <div className="table-responsive">
                                    <table class="table ">
                                        <thead>
                                            <tr>
                                            <th className="check"></th>
                                                <th className="table-h1 product sub-text table-bordered">Product</th>
                                                <th className="table-h1 product-name sub-text table-bordered">Product Name</th>
                                                <th className="table-h1  size sub-text table-bordered">Pack Size</th>
                                                <th className="table-h1 packs  sub-text table-bordered">Qty Of Packs</th>
                                                <th className="table-h1 amount sub-text table-bordered">Amount</th>
                                                <th className="table-h1  delete sub-text table-bordered">Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        
                                            <tr>
                                            <td className="check"> <Form.Check type="checkbox" /></td>
                                                <td className="main-data table-bordered">
                                                    <div className="image-cart">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assests/Carts/cart1.png"}
                                                            className="product"
                                                            alt="Mansa Nutri Logo"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="main-data mini-text table-bordered">Balsam Tolu (Reconstitution)</td>
                                                <td className="main-data mini-text table-bordered">1 G</td>
                                                <td className="main-data table-bordered">
                                                    <IncreamentDecreament />
                                                </td>
                                                <td className="main-data mini-text table-bordered">₹.408</td>
                                                <td className="main-data table-bordered">
                                                <div className="delete-btn table-bordered" onClick={handleShowModal}>
                                                        <FontAwesomeIcon icon={faTrash} className="icon" />
                                                    </div>
                                                    {/* <Button_Common className="delete" /> */}
                                                </td>
                                            </tr>
                                            <tr>
                                            <td className="check"> <Form.Check type="checkbox" /></td>
                                                <td className="main-data table-bordered">
                                                    <div className="image-cart">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assests/Carts/cart2.png"}
                                                            className="product"
                                                            alt="Mansa Nutri Logo"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="main-data mini-text table-bordered">Carrot Seeds</td>
                                                <td className="main-data mini-text table-bordered">1 G</td>
                                                <td className="main-data table-bordered">
                                                    <IncreamentDecreament />
                                                </td>
                                                <td className="main-data mini-text table-bordered">₹.6889</td>
                                                <td className="main-data table-bordered">
                                                <div className="delete-btn table-bordered" onClick={handleShowModal}>
                                                        <FontAwesomeIcon icon={faTrash} className="icon" />
                                                    </div>
                                                    {/* <Button_Common className="delete" /> */}
                                                </td>
                                            </tr>
                                            <tr>
                                            <td className="check"> <Form.Check type="checkbox" /></td>
                                                <td className="main-data table-bordered">
                                                    <div className="image-cart">
                                                        <img
                                                            src={process.env.PUBLIC_URL + "/assests/Carts/cart3.png"}
                                                            className="product"
                                                            alt="Mansa Nutri Logo"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="main-data mini-text table-bordered">Cassia Flower</td>
                                                <td className="main-data mini-text table-bordered">1 G</td>
                                                <td className="main-data table-bordered">
                                                    <IncreamentDecreament />
                                                </td>
                                                <td className="main-data mini-text table-bordered">₹.567</td>
                                                <td className="main-data table-bordered">
                                                    <div className="delete-btn table-bordered" onClick={handleShowModal}>
                                                        <FontAwesomeIcon icon={faTrash} className="icon" />
                                                    </div>
                                                   
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                            </Col>
                            <Col xxl={3} xl={3} lg={6}>
                                <div className="Order-Summary">
                                    <h1 className="sub-text ">Order Summary</h1>
                                    <div className="Total main">
                                        <p className="mini-text">Total amount</p>
                                        <p className="mini-text">₹90</p>
                                    </div>
                                    <div className="Total main">
                                        <p className="mini-text">Discount</p>
                                        <p className="mini-text">₹90</p>
                                    </div>
                                    <div className="Total main">
                                        <p className="mini-text">Tax</p>
                                        <p className="mini-text">₹90</p>
                                    </div>
                                    <div className="Total ">
                                        <p className="mini-text">Shipping</p>
                                        <p className="mini-text">₹90</p>
                                    </div>
                                   <div className="total-payment">
                                   <div className="total-main">
                                        <p className="sub-text">Total</p>
                                        <p className="sub-text">₹90</p>
                                    </div>
                                   </div>
                                   <Link to="/address">
                                   <div className="Proceed">
                                     <Button_Common text="Proceed to Checkout " className="check text green"/>
                                   </div>
                                   </Link>
                                  
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Bottom_Successfull_Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    text="Item Deleted from the cart successfully!"
                />
        </>
    );
};

export default CartPage;
