import React, { useState, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Blog_card from "../CommonComponents/Blog_card/Blog_card";
import Banner_Heading_Text from "../CommonComponents/Banner_Heading_Text/Banner_Heading_Text";
import Paginations from "../CommonComponents/Paginations/Paginations";
import "./Blog_Inner.css";
import RecentBlogs from "../CommonComponents/RecentBlogs/RecentBlogs";
import "../CommonComponents/RecentBlogs/RecentBlogs.css";
function Blog_Inner() {
  const [breadcrumbItems, setBreadcrumbItems] = useState([
    { text: "Home", link: "/" },
    { text: "Blogs", link: "/blog" },
    {
      text: "CHECK OUT THE BEST ESSENTIAL OIL AS PER NEW RESEARCH",
      link: "/blog-inner",
      className: "active",
      style: { color: "green" },
    },
  ]);

  const [showRecentFeeds, setShowRecentFeeds] = useState(false);
  const recentFeedsRef = useRef(null);
  const blogpost = [
    {
      image: process.env.PUBLIC_URL + "/assests/Blogs/blog1.png",
      title: "Black Pepper Essential Oils",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Blogs/blog2.png",
      title: "Bergamot Essential Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Blogs/blog3.png",
      title: "Bitter Orange Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Blogs/blog4.png",
      title: "Carrot Seed Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Blogs/blog1.png",
      title: "Black Pepper Essential Oils",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Blogs/blog2.png",
      title: "Bergamot Essential Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/ProductCards/p3.png",
      title: "Bitter Orange Oil",
      price: "00.00",
    },
    {
      image: process.env.PUBLIC_URL + "/assests/Blogs/blog3.png",
      title: "Carrot Seed Oil",
      price: "00.00",
    },
  ];

  return (
    <>
      <section className="Blog-Inner-Page">
        <Container>
          <Row>
            <Col xxl={8} xl={8} lg={8} md={12}>
              <Banner_Heading_Text
                headingText={"Essentials Blogs"}
                breadcrumbItems={breadcrumbItems}
              />
              <div className="Blog-Inner">
                <div className="image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assests/Blogs/blog-inner.png"
                    }
                    className="inner-image"
                    alt="Mansa Nutri Logo"
                  />
                </div>
                <h1 className="mid-title ">
                  Check out the best essential oil as per new research
                </h1>
                <h1 className="price-text">
                  Exploring the Power of Essential Oils: Recent Research
                  Insights
                </h1>
                <h1 className="price-text intro">Introduction:</h1>
                <p className="mini-text">
                  In recent years, essential oils have gained popularity not
                  only for their aromatic properties but also for their
                  potential health benefits. From stress relief to immune
                  support, the research surrounding essential oils continues to
                  expand, shedding light on their diverse applications. Let's
                  delve into some of the intriguing findings from recent studies
                  in the field of essential oils.
                </p>
                <div className="Blog-inner-content">
                  <ul>
                    <li>
                      <span className="mini-text main">
                        1. Lavender Oil and Anxiety Reduction:
                      </span>
                      <p className="mini-text">
                        Research published in the Journal of Alternative and
                        Complementary Medicine explores the anxiolytic effects
                        of lavender oil. The study suggests that inhaling
                        lavender essential oil may significantly reduce anxiety
                        levels in patients undergoing various medical
                        procedures, offering a natural alternative to
                        conventional anxiolytic medications.
                      </p>
                    </li>
                    <li>
                      <span className="mini-text main">
                        2. Tea Tree Oil for Skin Health:
                      </span>
                      <p className="mini-text">
                        A study featured in the International Journal of
                        Dermatology examines the antimicrobial properties of tea
                        tree oil. Findings reveal its efficacy in combating
                        acne-causing bacteria and reducing inflammation
                        associated with acne lesions. Incorporating tea tree oil
                        into skincare routines may offer a natural approach to
                        managing acne & promoting clear, healthy skin.
                      </p>
                    </li>
                    <li>
                      <span className="mini-text main">
                        3. Peppermint Oil and Cognitive Performance:
                      </span>
                      <p className="mini-text">
                        Recent research highlighted in the journal Nutritional
                        Neuroscience investigates the cognitive-enhancing
                        effects of peppermint essential oil. The study suggests
                        that inhaling peppermint oil may improve cognitive
                        performance, including memory & attention, providing a
                        potential remedy for cognitive fatigue & enhancing
                        mental clarity.
                      </p>
                    </li>
                    <li>
                      <span className="mini-text main">
                        4. Eucalyptus Oil for Respiratory Health:
                      </span>
                      <p className="mini-text">
                        Studies published in Respiratory Medicine and
                        Evidence-Based Complementary and Alternative Medicine
                        explore the therapeutic effects of eucalyptus essential
                        oil on respiratory conditions. Inhalation of eucalyptus
                        oil vapor is found to alleviate symptoms of respiratory
                        infections, such as coughing and congestion, due to its
                        bronchodilator and expectorant properties.
                      </p>
                    </li>
                  </ul>
                </div>
                <span className="mini-text main">Conclusion:</span>
                <p className="mini-text">
                  The evolving body of research surrounding essential oils
                  continues to uncover their potential therapeutic properties
                  across various domains, from mental health to skincare and
                  beyond. These recent studies provide valuable insights into
                  the efficacy & mechanisms of action of essential oils, paving
                  the way for their integration into holistic health & wellness
                  practices. As scientific exploration in this field progresses,
                  the profound benefits of essential oils may continue to enrich
                  our lives in countless ways.
                </p>
              </div>
            </Col>
            <Col xxl={4} xl={4} lg={4} md={12} sm={12} xs={12}>
              <div className="Recent-blogs">
                <div className="recent-border"></div>
                <h1 className="mid-title">Recent Feeds</h1>
              </div>
              <div className="Main-Blog">
                <div className="row">
                  <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-6 col-12">
                    <div className="blogs-Main">
                      <div className="scrollbar" id="style-3">
                        <div className="Recents" data-mcs-theme="dark">
                          <div className="recent-feeds">
                            {blogpost.map((item, index) => (
                              <div key={index} className="blogs">
                                <div className="blog-feed">
                                  <div className="content-blog">
                                    <img
                                      src={item.image}
                                      className="feed"
                                      alt="Feed"
                                    />
                                  </div>
                                  <div className="feed-content">
                                    <p className="text-feed">{item.title}</p>
                                    <p className="text">{item.price}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="overlay"></div>
                      <div className="icon">
                        <FontAwesomeIcon icon={faAngleDown} />
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-12 col-xl-12  col-lg-12 col-md-6 col-12">
                    <div className="categories">
                      <h1 className="mid-title">Categories</h1>
                      <p className="price-text hover-one">Phytochemical (8)</p>
                      <p className="price-text hover-one">Carrier Oils (3)</p>
                      <p className="price-text hover-one">Essential Oils (3)</p>

                      <h1 className="mid-title">Popular Tags</h1>
                      <div className="oils">
                        <p className="price-text hover-two">Cosmetics</p>
                        <p className="price-text hover-two">Health</p>
                        <p className="price-text hover-two">Lifestyle</p>
                      </div>
                      <div className="oils">
                        <p className="price-text hover-two">Natural</p>
                        <p className="price-text hover-two">Rosemary Oil</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Blog_Inner;
