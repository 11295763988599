import React, {useEffect, useState} from "react";
import {Container, Form} from "react-bootstrap";
import Common_Button from "../CommonComponents/Button_Common/Button_Common";
import Successfull_Modal from "../CommonComponents/Common_Modal/Successfull_Modal/Successfull_Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../CommonComponents/Breadcrumbs/Breadcrumbs";
import "./Change_Password.css";
import {Link, useNavigate} from "react-router-dom";
function Change_Password() {
    const [modalSuccessfullShow, setSuccessfullModalShow] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [reEnterPasswordVisible, setReEnterPasswordVisible] = useState(false);

    // Function to toggle password visibility
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    // Function to toggle confirm password visibility
    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    // Function to toggle confirm password visibility
    const toggleReEnterPasswordVisibility = () => {
        setReEnterPasswordVisible(!reEnterPasswordVisible);
    };
    const [breadcrumbItems, setbreadcrumbItems] = useState([
        {text: "HOME", link: "/"},
        {text: "ACCOUNT", link: "/account"},
        {text: "PASSWORD CHANGE", link: "/"},
        ,
    ]);
    const handleClick = () => {
       
        setSuccessfullModalShow(true);

      
        setTimeout(() => {
            setSuccessfullModalShow(false);
        }, 3000); 
    };


   
    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        if (modalSuccessfullShow) {
            timer = setTimeout(() => {
                setSuccessfullModalShow(false);
                navigate("/account"); 
            }, 1000); 
        }

       
        return () => clearTimeout(timer);
    }, [modalSuccessfullShow, navigate]);
    return (
        <>
            <section className="Change">
                <Container>
                    <Breadcrumbs items={breadcrumbItems} />
                    <h1 className="mid-title">Password Change</h1>
                    <div className="Login_Activity Modal-holder">
                        <div className="row justify-content-center">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-xs-12 col-12">
                                <div className="Login_Activity_Form_Holder">
                                    <div className="LogIn">
                                        <div className="row bg-color me-0 ms-0">
                                            <div className="col-md-12 p-0">
                                                <div className="row justify-content-center me-0 ms-0  ">
                                                    <div className="col-md-12">
                                                        <div className="log_in_form">
                                                            <div className="edit_span_text_holder">
                                                                <span className="edit_span_text">Edit</span>
                                                            </div>
                                                            <Form>
                                                                <Form.Group
                                                                    className="password-field-holder  mb-3"
                                                                    controlId=""
                                                                >
                                                                    <Form.Control
                                                                        type={passwordVisible ? "text" : "password"}
                                                                        placeholder="Current Password"
                                                                    />
                                                                    <div
                                                                        className="eye-icon-holder"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            className="eye-icon"
                                                                            icon={passwordVisible ? faEye : faEyeSlash}
                                                                        />
                                                                    </div>
                                                                </Form.Group>

                                                                <Form.Group
                                                                    className="password-field-holder  mb-3"
                                                                    controlId=""
                                                                >
                                                                    <Form.Control
                                                                        type={
                                                                            confirmPasswordVisible ? "text" : "password"
                                                                        }
                                                                        placeholder="Change Password"
                                                                    />
                                                                    <div
                                                                        className="eye-icon-holder"
                                                                        onClick={toggleConfirmPasswordVisibility}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            className="eye-icon"
                                                                            icon={
                                                                                confirmPasswordVisible
                                                                                    ? faEye
                                                                                    : faEyeSlash
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Form.Group>

                                                                <Form.Group
                                                                    className="password-field-holder  mb-3"
                                                                    controlId=""
                                                                >
                                                                    <Form.Control
                                                                        type={
                                                                            reEnterPasswordVisible ? "text" : "password"
                                                                        }
                                                                        placeholder="Re-Enter New Password"
                                                                    />
                                                                    <div
                                                                        className="eye-icon-holder"
                                                                        onClick={toggleReEnterPasswordVisibility}
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            className="eye-icon"
                                                                            icon={
                                                                                reEnterPasswordVisible
                                                                                    ? faEye
                                                                                    : faEyeSlash
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Form.Group>
                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="btn-holder yes_no_btn_holder">
                                    <Link to="/account">
                                        <Common_Button
                                            className="SubmitBtn green text me-3"
                                            text="CANCEL"
                                           
                                        />
                                    </Link>

                                    <Common_Button
                                        className="YesBtn brown text"
                                        text="SAVE PASSWORD"
                                        onClick={() => {
                                            setSuccessfullModalShow(true);
                                        }}
                                       
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <Successfull_Modal
                show={modalSuccessfullShow}
                onHide={() => {
                    setSuccessfullModalShow(false);
                }}
                message={"Password changes Successfully!"}
            />
        </>
    );
}

export default Change_Password;
