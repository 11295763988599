import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import "./Yoga.css";
const Yoga = () => {
    return (
        <>
            <section className="Yoga">
                <div className="Main">
                    <div className="yoga-content">
                        <img src={process.env.PUBLIC_URL + "/assests/Home/yoga/main.png"} className="yoga" />
                        <img src={process.env.PUBLIC_URL + "/assests/Home/yoga/home _  yoga.png"} className="yoga2" />

                        <Container>
                            <div className="content">
                                <h1 className="mid-title">Best Essential Oils for Yoga and Meditation</h1>
                                <div className="Main-Content">
                                    <div className="Meditations">
                                        <div className="circle">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assests/Home/yoga/imag1.png"}
                                                className="imag-1"
                                            />
                                        </div>
                                        <div className="meditation-content">
                                            <h1 className="awake">Awake & Mindful</h1>
                                            <p className="mini-text">
                                                Aromatic herbs and oils, integral to various religions and cultures,
                                                enhance spiritual practices. Regular use of essential oils can enrich
                                                your experience.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="Meditations">
                                        <div className="circle">
                                            <img
                                                src={process.env.PUBLIC_URL + "/assests/Home/yoga/image2.png"}
                                                className="imag-1"
                                            />
                                        </div>
                                        <div className="meditation-content">
                                            <h1 className="awake">Oils for Yoga and Meditation</h1>
                                            <p className="mini-text">
                                                Enhance your yoga and meditation with our potent essential oils like
                                                frankincense, rose, lavender, ylang-ylang, sandalwood, peppermint,
                                                orange, jasmine, clary sage, and more.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Yoga;
