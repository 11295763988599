import React from "react";
import { Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

function Return_Reason_Modal(props) {
  const { isOpen, onClose } = props;

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      size="md"
      className="Modal-holder"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Select a reason for return
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="select_reason">
          <Form>
            <Form.Check
              label="Product price has reduced"
              name="reason"
              type="radio"
              className="mini-text"
            />
           <Form.Check
              label="Wrong contact number entered"
              name="reason"
              type="radio"
              className="mini-text"
            />
         
         <Form.Check
              label="Incorrect product size/colour/type ordered"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Purchased product from somewhere else"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Wrong address selected"
              name="reason"
              type="radio"
              className="mini-text"
            />
             <Form.Check
              label="Product not required anymore"
              name="reason"
              type="radio"
              className="mini-text"
            />
             <Form.Check
              label="Incorrect payment method selected"
              name="reason"
              type="radio"
              className="mini-text"
            />
             <Form.Check
              label="Expected delivery time is too long"
              name="reason"
              type="radio"
              className="mini-text"
            />
            <Form.Check
              label="Other"
              name="reason"
              type="radio"
              className="mini-text"
            />
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Return_Reason_Modal;
